import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from './Text';
import { tablet, useQuery } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';
import CirclePlus from 'assets/icons/circle-plus.svg';
import CircleMinus from 'assets/icons/circle-minus.svg';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: #f6f4ee;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  background-color: ${({ theme }) => theme.colors.light0};
  border-bottom: 1px solid #dfdfec;
  cursor: pointer;
  padding: 0.75rem 1rem 0 1rem;
  -webkit-tap-highlight-color: transparent;
  div:nth-child(1n):not(:last-child) {
    margin-bottom: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
  color: 'dark80',
})`
  transition: color 0.2s ease;
  flex: 1;
  margin-right: 1rem;
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.375rem; /* 137.5% */
`;

const List = styled.div`
  max-width: 28.75rem;
  width: 100%;
  margin-top: 3rem;
  border-radius: 0.75rem;
  overflow: hidden;
  div:last-child {
    border-bottom: 0;
  }
  @media ${tablet} {
    margin-top: 2rem;
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  margin-top: 0.75rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  -webkit-tap-highlight-color: transparent;
`;

const Answer = styled(Text)<{ expanded: boolean }>`
  padding-bottom: 1.5rem;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  span {
    font-weight: 700;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2rem;
`;

const PeopleAskUs: FC<PeopleAskUsProps> = ({ title, list }) => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);
  const { isTablet } = useQuery();
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-mw';

  const handleQuestionClick = (index: number, item: Item) => {
    handleClick(index);
    Analytics.logEvent({
      event: 'FAQ-clicks',
      funnel: quiz,
      widget: item.question,
    });
  };

  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const renderItem = (item: Item, index: number) => {
    const key = `ask-us-question-${index}`;
    const expanded = expandedQuestions.includes(index);

    return (
      <QuestionContainer
        key={key}
        onClick={() => handleQuestionClick(index, item)}
        expanded={expanded}
      >
        <TitleContainer>
          <Question>{item.question}</Question>
          {expanded ? <CircleMinus /> : <CirclePlus />}
        </TitleContainer>

        <AnswerContainer expanded={expanded}>
          <div ref={childRef}>
            {item.answer.map(answer => (
              <Answer
                expanded={expanded}
                key={answer}
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            ))}
          </div>
        </AnswerContainer>
      </QuestionContainer>
    );
  };

  return (
    <Container>
      <Title>{title}</Title>

      <List>{list.map(renderItem)}</List>
    </Container>
  );
};

export default React.memo(PeopleAskUs);
